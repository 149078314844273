@import url('./abstracts/_variables.css');
@import url('./abstracts/_reset.css');

@import url('./base/_base.css');
@import url('./base/_icon.css');
@import url('./base/_typography.css');
@import url('./base/_utilities.css');
@import url('./base/_container.css');
@import url('./base/_grid.css');

@import url('./component/_filter-form.css');
@import url('./component/_input.css');
@import url('./component/_table.css');
@import url('./component/_card.css');
@import url('./component/_message.css');
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.error-span {
  font-size: 13px;
  position: relative;
  top: 0px;
  font-style: italic;
  color: red;
  font-weight: normal!important;
}
.select-box > div .error-span {
  font-size: 13px;
  position: relative;
  top: 3px;
  font-style: italic;
  color: red;
}
/* TODO delete unused files */

.overlay-body {
  overflow: hidden;
}

html * {
  color: inherit;
}
.relative {
  position: relative
}

textarea {
  resize: none;
  font-family: inherit;
}

body{
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}
iframe{
  color-scheme: normal!important;
}

.iubenda-tp-btn[data-tp-float][data-tp-float=bottom-right]{
  display:none!important;
}

:global(#kayako-messenger) {
  z-index: 4 !important;
}