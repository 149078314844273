@import url(./_grid-order.css);
/*   
// name     breakpoint   container
// xs       <576px       None (auto)
// sm       ≥576px       540px
// md       ≥768px       720px
// lg       ≥992px       960px
// xl       ≥1200px      1140px
// xxl      ≥1400px      1320px */

:root {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 0;
}

@media screen and (min-width: 768px) {
  :root {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.d-f,
.flex,
.display-f,
.flex-wrapper {
  display: flex;
}
d-n {
  display: none;
}
.jc-sa {
  justify-content: space-around;
}
.jc-c {
  justify-content: center;
}
.jc-sb {
  justify-content: space-between;
}
.jc-se {
  justify-content: space-evenly;
}
.jc-fe {
  justify-content: flex-end;
}
.jc-fs {
  justify-content: flex-start;
}
.ai-c {
  align-items: center;
}
.ai-fe {
  align-items: flex-end;
}
.ai-fs {
  align-items: flex-start;
}
.fw-n {
  flex-wrap: nowrap;
}
.fw-w {
  flex-wrap: wrap;
}
.fw-wr {
  flex-wrap: wrap-reverse;
}
.fd-c {
  flex-direction: column;
}
.f-3 {
  flex: 3;
}
.f-2 {
  flex: 2;
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.fw-n > * {
  flex-shrink: 1;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
}
.col {
  flex: 1 0 0%;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

/* Force next columns to break to new line */
.w-100 {
  width: 100% !important;
}

.w-25 {
  width: 25% !important;
}
