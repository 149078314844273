.clearfix {
  clear: both;
}
.center {
  text-align: center;
}
.inline span {
  font-size: 18px;
  vertical-align: text-bottom;
}
.flex {
  display: flex;
}
.mt-md {
  margin-top: 8px !important;
}
.mt-xl {
  margin-top: 16px !important;
}
.mt-xxl {
  margin-top: 25px !important;
}
.mb-xl {
  margin-bottom: 16px !important;
}
.mb-md {
  margin-bottom: 8px !important;
}
.mb-xxl {
  margin-bottom: 40px;
}
.mb-larger {
  margin-bottom: 60px;
}
.mrl-md {
  margin: 0 5px;
}
.mr-md {
  margin-right: 5px;
}
.ml-md {
  margin-left: 5px;
}
.m-10 {
  margin: 0 10px;
}
.mb-l {
  margin-bottom: 16px !important;
}
.mb-l {
  margin-bottom: 16px;
}
.ta-c {
  text-align: center;
}
.m-md {
  margin-bottom: 8px;
}
.pd-xs {
  padding: 0px 8px;
}
.f-1 {
  flex: 1;
}
.g-5 {
  gap: 5px;
}
.g-10 {
  gap: 10px;
}
.g-20 {
  gap: 20px;
}
.g-30 {
  gap: 30px;
}
.fd-r {
  flex-direction: row;
}
.fd-rr {
  flex-direction: row-reverse;
}
.fd-cr {
  flex-direction: column-reverse;
}
.border-bottom {
  border-bottom:var(--line-header);
}
.p-sm {
  padding: 12px 0px;
}
.pb-md {
  padding-bottom: 12px;
}

.o5 {
  opacity: 0.5;
}

.o8 {
  opacity: 0.8;
}

.h-100 {
  height: 100% !important;
}

