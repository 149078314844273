.action-bar-mobile {
  border-bottom: solid thin var(--th);
  display: flex;
  margin-bottom: var(--margin-md);
}
.action-bar-mobile .action-search {
  flex: 1;
}

.w-100,
.w-60,
.w-50,
.w-30 {
  flex-basis: 100%;
  flex-grow: 0;
  max-width: 100%;
}

@media only screen and (min-width: 992px) {
  .w-wrapper {
    display: flex;
    gap: 30px;
  }
  .w-100 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
  .w-50 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }
  .w-60 {
    flex-basis: 66%;
    flex-grow: 0;
    max-width: 66%;
  }
  .w-30 {
    flex-basis: 33.3%;
    flex-grow: 0;
    max-width: 33%;
  }
}
