html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: var(--foreground);
  height: 100%;
  overflow: unset;
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* here is the styles for mobile design*/
@media screen and (min-width: 576px) {
} /*IPads, Tablets.*/
@media screen and (min-width: 768px) {
} /*Small screens, laptops.*/
@media screen and (min-width: 992px) {
} /*Desktops, large screens.*/
@media screen and (min-width: 1200px) {
} /*Extra large screens, TV.*/
