@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Inter', sans-serif;
}

h1 {
  font-size: 3.5rem;
  font-weight: 800;
}

h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

h3 {
  font-size: 1.8rem;
  font-weight: 500;
}

h4 {
  font-size: 1.5rem;
  font-weight: 500;
}

h5 {
  font-size: 1.25rem;
  font-weight: 500;
}

h6 {
  font-size: 1.1rem;
  font-weight: 400;
}

p {
  font-size: 1rem;
}

.pdbDescSectionTitle {
  font-weight: 700;
  display: inline-block;
  padding-right: 5px;
  font-size: 13px;
}
.pdbDescSection {
  display: block;
  line-height: 19px;
}
.pdbDescSectionText {
  font-size: 13px;
}
