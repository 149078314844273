table {
  font-size: 14px;
  border: none;
  outline: none;
  width: 100%;
  border-collapse: collapse;
  position: relative;
}

thead tr:first-child {
  background: var(--th);
  border: transparent;
}

thead tr th {
  height: 40px;
  text-align: left;
  padding: 4px;
}

tbody tr td {
  padding: 4px;
  height: 36px;
  border: none;
  border-bottom: solid thin var(--input-hover);
}

tbody tr {
  cursor: pointer;
}
tbody tr:hover {
  background: none;
}
tbody tr.clicked {
  background: none;
  font-weight: 500;
}

.zebra-even table tbody tr:nth-child(even) {
  background: var(--tr-zebra);
}
.zebra-odd table tbody tr:nth-child(odd) {
  background: var(--tr-zebra);
}

.customizable {
  position: absolute;
  right: 20px;
  top: 9px;
  z-index: 1;
}
.customizable .customize-link {
  cursor: pointer;
}
.customizable .columns-list {
  line-height: 2;
}

  tbody tr:hover {
    background: var(--input-hover);
  }
  tbody tr.clicked {
    background: var(--tb-clicked);
    font-weight: 500;
  }
tr.selected-family {
  background: var(--group-row);
  font-weight: 500;
}
