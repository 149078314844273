input,
select {
  font-size: 16px;
  font-family: inherit;
  outline: none;
  border: none;
  border: 1px solid var(--grey-lighter);
  border-radius: 5px;
  height: 40px;
  background: var(--background-utils);
  transition: 0.3s ease;
}

input.large {
  height: 50px;
  margin-bottom: 1em;
  border: 0.5px solid var(--grey-lighter);
  font-weight: 500;
}

input:focus,
select:focus {
  border: solid thin var(--input-border);
  transition: all 0.15s ease-in;
  box-shadow: var(--shadow-input);
}

select::-ms-expand {
  display: none;
}

input:not([type='checkbox']),
select {
  width: 100%;
  padding: 0.5rem 0.8rem;
}

/* Todo talk about vertical align */

input[type='checkbox']:not(.switch),
input[type='radio']:not(.switch) {
  --active: var(--primary);
  --active-inner: #fff;
  --focus: 2px rgba(0, 228, 122, 0.3);
  --background: rgba(255, 255, 255, 0);
  --disabled: #f6f8ff;
  --disabled-inner: #e1e6f9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 18px;
  outline: none;
  display: inline-block;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--grey-lighter);
  background: var(--background);
  transition: 0.3s;
  z-index: 1;
}
input[type='checkbox'].large,
input[type='radio'].large {
  height: 25px;
  width: 25px;
}
input[type='checkbox']:after,
input[type='radio']:after {
  content: '';
  display: block;
  left: 0;
  top: 0;
  position: absolute;
  transition: 0.3s ease;
  border-color: var(--accent);
}

input[type='checkbox']:checked,
input[type='radio']:checked {
  background: var(--accent);
  transition: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  border-color: var(--accent);
}

input[type='checkbox']:disabled,
input[type='radio']:disabled {
  background: var(--disabled);
  cursor: not-allowed;
  opacity: 0.9;
}

input[type='checkbox']:disabled:checked,
input[type='radio']:disabled:checked {
  background: var(--disabled-inner);
  border-color: var(--grey-lighter);
}

input[type='checkbox']:disabled + label,
input[type='radio']:disabled + label {
  cursor: not-allowed;
}

input[type='checkbox']:hover:not(:checked):not(:disabled),
input[type='radio']:hover:not(:checked):not(:disabled) {
  border-color: var(--accent);
}

input[type='checkbox']:focus,
input[type='radio']:focus {
  box-shadow: 0 0 0 var(--focus);
}

input[type='checkbox'],
input[type='radio'] {
  width: 18px;
}

input[type='checkbox']:after,
input[type='radio']:after {
  opacity: var(--o, 0);
}

input[type='checkbox']:checked,
input[type='radio']:checked {
  --o: 1;
}

input[type='checkbox'] {
  border-radius: 3px;
}

input[type='checkbox']:after {
  width: 5px;
  height: 8px;
  border: 2px solid var(--active-inner);
  border-top: 0;
  border-left: 0;
  left: 6px;
  top: 4px;
  transform: rotate(var(--r, 20deg));
}
input[type='checkbox'].large:after {
  width: 7px;
  height: 11px;
  border: 2px solid var(--active-inner);
  border-top: 0;
  border-left: 0;
  left: 8px;
  top: 5px;
  transform: rotate(var(--r, 20deg));
}

input[type='checkbox']:checked {
  --r: 43deg;
}

input[type='radio'] {
  border-radius: 50%;
  position: relative;
  padding: 0.5rem 0.5rem;
}

input[type='radio']:after {
  width: 18px;
  height: 18px;
  left: -1px;
  border-radius: 50%;
  background: var(--active-inner);
  -webkit-transform: scale(var(--s, 0.7));
  transform: scale(var(--s, 0.9));
  margin: auto;
  text-align: center;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

input[type='radio'].large:after {
  width: 25px;
  height: 25px;
}

input[type='radio']:checked {
  --s: 0.6;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: var(--background-utils) !important;
  -webkit-text-fill-color: var(--foreground);
  transition: background-color 5000s ease-in-out 0s;
}

@media only screen and (min-width: 768px) {
  input,
  select {
    font-size: 15px;
  }
}

input:hover {
  border: 1px solid var(--input-border);
}

.form-item label {
  margin-bottom: 0.5em;
  display: inline-block;
}
.form-item label {
  font-size: 14px;
}
