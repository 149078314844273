.filter form > div {
  padding: var(--pd-filter);
  display: flex;
  flex-direction: column;
  width: 100%;
}
.filter form > div label {
  font-size: 15px;
  color: var(--foreground);
  font-weight: 500;
}

.filter form > button {
  margin-top: 32px;
}

.list-table {
  min-height: 400px !important;
  position: relative;
}
