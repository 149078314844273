.confirm-email-message-m {
  width: 100%;
  background: #fff6d0;
  border: 1px solid var(--warning);
  box-sizing: border-box;
  border-radius: 5px;
  color: black;
  margin-top:5px;
}
.confirm-email-message-box {
  padding: 1em;
  width: 100%;
}
.confirm-email-message-box .icon {
  position: absolute;
  top: 8px;
  font-size: 20px;
  right: 8px;
  cursor: pointer;
}

@media all and (min-width: 992px) {
  .confirm-email-message-box {
    padding: 0.3em 2.2em;
    width: 100%;
  }
  .confirm-email-message-box .button {
    margin-top: 0px !important;
  }
}